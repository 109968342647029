.welcome-text.row {
  z-index: 100;
  position: relative;
}
.tablecustomshrink {
  width: 90% !important;
  margin: 0 auto;
}

.welcome-text.row {
  z-index: 100;
  position: relative;
}
.tablecustomshrink {
  width: 90% !important;
  margin: 0 auto;
}

.Modalmember {
  padding-bottom: 30px;
}

.Modalmember .d-flex {
  display: flex !important;
  align-items: center;
  gap: 20px;
}
.login-box a {
  color: #4f75ff;
  font-weight: 600;

  border-radius: 6px;
  transition: all 0.3s;
}
.loginlinks a {
  padding: 20px 15px;
}

.uploadBody button#dropdown-basic {
  background: #3c3d37;
  border: none;
}

.Memberaddition .modal-dialog {
  max-width: 800px;
}

.Memberaddition .sh-pass span.show-pass {
  top: 10px;
}

/* Sort Controls */
.sort-controls {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 10px;
}

.sort-label {
  font-weight: bold;
  color: #333;
}

.sort-select {
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f8f9fa;
  font-size: 16px;
}

.sort-order-button {
  background-color: #5a5a5a;
  color: #fff;
  padding: 5px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sort-order-button:hover {
  background-color: #5a5a5a;
}
.inputcheckprivacy input.form-check-input {
  border: 2px solid #5b5b5b;
}

button.addTestclass {
  background-image: linear-gradient(to right, #5b5b5b, #1c1b1b);
  border: none;
}
.templateForm {
  margin: 0 auto;
  max-width: 970px;
}
.templateForm td.mt-3.my-2 {
  padding-top: 20px;
}

/* audio style */

.custom-audio::-webkit-media-controls-panel {
  background-color: #25b8bf;
  border-radius: 8px;
}

.custom-audio::-webkit-media-controls-play-button,
.custom-audio::-webkit-media-controls-pause-button {
  color: green;
}

.custom-audio::-webkit-media-controls-timeline {
  background-color: red; /* Lighter shade for the timeline */
}

.custom-audio::-webkit-media-controls-volume-slider {
  background-color: purple;
}
