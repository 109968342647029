/* Change the background color for the unchecked state */
.ant-switch {
    background: linear-gradient(to right, #F30000, #810000) !important;
  }
  
  /* Change the background color for the checked state */
  .ant-switch-checked {
    background: linear-gradient(to right, #42D000, #008104) !important;
  }
  
  /* Optional: Customize the focus ring */
  .ant-switch:focus {
    box-shadow: none;
  }

/* ::-webkit-scrollbar{
    display: none;
} */

.ant-modal-content{
  background-color: #DDDDDD !important;
}

.ant-modal-close{
  height: auto !important;
  right: 0 !important;
}