.datasheet-footer {
    background-color: #ffffff;
    padding: 20px 0px;
    font-family: Arial, sans-serif;
    border-top: 1px solid #ccc;
    text-align: center;
    line-height: 1.5;
  }
  
  .footer-title {
    font-weight: bold;
    font-size: 14px;
    color: #d11a1a;
    margin-bottom: 10px;
  }
  /* .signaturePosition {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100px; 
    text-align: center;
    border-top: 1px solid #ccc;
    padding-top: 10px;
    font-size: 14px;
    background: white;
    page-break-before: always; 
  } */
  .signaturePosition {
    max-width: 300px;
    margin-right: 0px;
    margin-left: auto;
}
  
.signatureImage {
  height: auto;
  display: block;
  margin: 0 auto;
  max-width: 150px;
  margin-top: 20px;
}
  
  .signatureTitle {
    font-size: 14px;
    margin-top: 5px;
    text-align: center;
  }
  
  .noSignatureText {
    font-size: 12px;
    color: #555;
  }
  
 
  .footer-offer {
    font-size: 16px;
    background: #004d00;
    font-weight: bold;
    margin-bottom: 15px;
    color: #ffff;
    padding: 10px;
}
.contactphone {
  position: relative;
  padding-left: 24px; /* Space for the icon */
}

.contactphone::before {
  content: "\260E"; /* Unicode for a telephone icon */
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px; /* Adjust icon size */
  color: #555; /* Icon color */
}
.footer-offer .contact-number {
    color: #ffffff;
    font-size: 16px;
    margin-left: 10px;
    text-decoration: none;
}
  
  .footer-details {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 15px;
  }
  
  .branch {
    margin: 10px;
    text-align: left;
  }
  
  .branch-title {
    font-weight: bold;
    color: #d11a1a;
    font-size: 14px;
}
  
  .footer-email {
    font-size: 14px;
    color: #333;
    display: flex;
  }
  
  .footer-email a {
    color: #000;
    text-decoration: none;
  }
  
  .footer-email a:hover {
    text-decoration: underline;
  }
  
.cstmmailabc{
  display: flex;
}
.footer-details.container.mt-4 .col-md-3 {

  margin-top: -10px;
}