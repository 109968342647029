.input-section input:focus {
  outline: none;
  box-shadow: none;
}

.input-section input::placeholder {
  opacity: 70%;
}

.pulse-effect {
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
      box-shadow: 0 0 0 0 rgb(247, 84, 84);
  }
  65% {
      box-shadow: 0 0 0 13px transparent;
  }
  100% {
      box-shadow: 0 0 0 0 transparent;
  } 
}