.date-input-meddate-container {
  position: relative;
  width: 70px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hidden-date-meddate-input {
  position: absolute;
  opacity: 0; /* Invisible but interactive */
  pointer-events: none; /* Prevent accidental direct clicks */
  z-index: -1;
}

.custom-meddate-display {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  font-family: Arial, sans-serif;
  font-size: 12px;
  text-align: center;
}

.custom-meddate-display:hover {
  border-color: #888;
  background-color: #e8e8e8;
}

.meddate-date {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 2px;
}

.meddate-month-year {
  font-size: 12px;
  color: #555;
}
