button.updatedetbtn.btn.btn-primary{
    color: #fff;
    background-image: linear-gradient(to right, #5b5b5b, #1c1b1b);
    padding: 5px 20px 5px 20px;
    border: none;
}
ul#settings-tabs li{
 
  
    border: none;
}
ul#settings-tabs .nav-link.active{
    color: #fff;
    background-image: linear-gradient(to right, #5b5b5b, #1c1b1b);
    padding: 8px 20px 8px 20px;
}
ul#settings-tabs .nav-link {
    color: black;
    font-weight: 400;
    font-size: 16px;
}

.heading-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    margin-left: 5px;
    margin-right: 5px;
}
.heading-text .gradient-text {
    position: relative;
    background-image: linear-gradient(to right, rgb(89, 89, 89), rgb(38, 37, 37));
    background-clip: text;            /* Standard property */
    -webkit-background-clip: text;    /* Vendor prefix for WebKit */
    color: transparent;
}

.heading-text .heading-text-line {
    flex-grow: 1;
    height: 2px;
    background-color: rgba(186, 186, 186, 1);
    margin-top: 24px;
}
.heading-text .gradient-text::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px;
    height: 2px;
    width: 100%;
    background-image: linear-gradient(to right, rgb(89 89 89), rgb(38 37 37));
}