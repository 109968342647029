.outpatient-sheet p {
  margin-bottom: -2px;
}

button.btn-print.btn.btn-primary {
  color: #fff;
  background-image: linear-gradient(to right, #5b5b5b, #1c1b1b);
  padding: 5px 20px 5px 20px;
  border: none;
}
.outpatient-sheet .notescolumn.col p {

  line-height: 20px !important;
}
/* .summaryquick.col::before {
  content: "";
  background: black;
  position: absolute;
  left: 0px;
  height: 100%;
  width: 2px;
  opacity: 0.5;
  top: 0px;
} */
/* .summaryquick.col {
  position: relative;
} */
.DetailContainer {
  margin-left: 23px;
  margin-right: 23px;
  margin-top: -10px;
  border-top: 2px solid;
}
table.table-striped.vitalspart.table.table-bordered.table-hover input.form-control-sm.form-control{
  text-align: center !important;
}
select {
  word-wrap: normal;
  border: 1px solid #ccc;
  padding: 10px 5px;
  margin-bottom: 8px;
  border-radius: 5px;
}
p.vitalssec {
  padding-left: 10px !important;
}
.appointmentend.col {
  max-width: 303px;
  margin-right: 0px;
  margin-left: auto;
  position: relative;
  padding-left: 5px;
}
.appointtable {
  border: none;
}

.hide-during-print {
  display: none !important;
}

.section-label {
  font-size: 16px;
  text-align: left;
  padding-right: 10px;
  padding-bottom: 0px;
  border: none;
  padding-top: 0px;
  width: 100%;
}

.section-colon {
  font-size: 16px;
  text-align: center;
  padding-right: 10px;
  padding-bottom: 0px;
  border: none;
  padding-top: 0px;
  width: 5%;
}

.section-value {
  font-size: 16px;
  text-align: left;
  padding-bottom: 0px;
  border: none;
  padding-top: 0px;
  width: 65%;
}

.section-address {
  font-size: 14px;
  padding-top: 5px;
}

.section-table {
  width: 100%;
  border-collapse: collapse;
}

.info-row .info-col {
  padding: 0;
  margin: 0;
}
.summaryquick.col-md-3.col {
  padding-right: 0px;
}
.ql-editor {
  padding-right: 6px !important;
}
.info-row .appointmentend {
  padding-left: 15px; /* Increased padding for spacing */
  float: right;
  padding-right: 15px;
}
table.cstmapponttble {
  border: none !important;
}
table.cstmapponttble td {
  border: none;
  padding-top: 0px;
  padding-bottom: 0px;
}
.appointmentend.info-col.col::before {
  content: "";
  background: black;
  position: absolute;
  left: 0px;
  height: 100%;
  width: 2px;
  opacity: 0.5;
  top: 0px;
}
p.vitalstable td.align-middle{
  padding-bottom: 10px !important;
  padding-top: 10px !important;
}

table.medicinetable.table.table-bordered {
  border: 2px solid;
}
button.printDisable.btn.btn-success {
  color: #fff;
  background-image: linear-gradient(to right, #5b5b5b, #1c1b1b);
  border: none;
}
.medicinetable td,
.medicinetable th {
  border: 2px solid black !important;
}
.medicinetable td {
  position: relative;
}
/* .medicinetable td::before {
  content: "✏️"; 
  position: absolute;
  left: 5px;
  font-size: 12px; 
  color: #007bff; 
  top: 50%; 
  transform: translateY(-50%); 
} */
@media (max-width: 1350px){
  .headercenter {
    margin-left: -78px !important;
  }
}

@media (max-width: 1100px) {
  .outpatient-sheet.container{
    margin-top: 20px !important;
  }
  table.cstmapponttble td{
    font-size: 14px !important;
  }
  .info-row .appointmentend{
padding-left: 0px !important;
  }
  td.doctorSize{
    width: 100%;
  }
  .headerPart{
    flex-direction: column;
  }
  .fuGhzR {
    margin: 0px;
  }
  .headercenter {
    margin-left: 0px !important;
}
.headerlft {
  margin-top: 0px !important;
}
.outpatient-sheet.container{
  padding-top: 0px !important;
}
}
@media (max-width: 1050px) {
  .summaryquick.col-md-3.col {
    width: 35%;
    padding-right: 0px;
}
.col-md-9.notescolumn.col {
  width: 65%;
}
  .info-row.clearfixer.row {
    /* display: block; */
    flex-direction: column;
    gap: 20px;
  }
  .appointmentend.info-col.col::before {
    display: none !important;
  }
}

@media (max-width: 850px) {
 

  td.doctorSize{
    font-size: 13px !important;
  }
  td.doctorSizeUs {
    width: 30% !important;
}
  td.lftdoctside{
    font-size: 13px !important;
  }
  .DetailContainer {
    margin-left: 5px;
    margin-right: 5px;
}
td.lftdoctside, td.rghttnmcreg {
  width: 100% !important;
  font-size: 12px !important;
  display: block;
} 
td.doctcolon{
  width: 5% !important;
}
  table.cstmapponttble td.agedet{
    width: 37% !important;
  }
  td.reswdthadjst {
    /* width: 35% !important; */
    padding-left: 0px !important;
    padding-right: 0px;
  }
  .info-row .appointmentend {
    padding: 0px !important;
  }
  .prescrespo {
    width: 60% !important;
  }
  .headerPart {
    flex-direction: column !important;
  }
  .headercenter {
    margin: 0px !important;
  }
  .headerlft {
    margin-top: 0px !important;
  }

 
}

@media (max-width: 480px) {
  .appointmentend.info-col.col {
    border: none !important;
    padding: 20px 0px !important;
  }
  .appointmentend.info-col.col::before {
    display: none !important;
  }
  .headerPart {
    display: block;
  }
  .headerlft {
    text-align: center;
    margin-top: 0px !important;
    margin: 0 auto;
  }
  .headerlft img {
    margin: 0 auto;
  }
}
