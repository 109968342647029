/* table {
  border: 2px solid #e5e7eb;
  border-collapse: collapse;
  width: 100%;
  text-align: center;
  border-radius: 20px;
} */

/* th,
td {
  border-bottom: 2px solid #e5e7eb;
  padding: 10px;
} */
table,
th,
td {
  border: 1px solid black;
}
table {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
  border: 1px solid transparent;
  text-align: center;
}

thead th {
  text-align: center;
  padding: 8px;
}

tbody td {
  text-align: center;
  padding: 5px;
}

.MuiAvatar-circular {
  width: 30px !important;
  height: 30px !important;
}

.box {
  transition: all 0.5s ease-in-out !important;
}

.box::-webkit-scrollbar {
  width: 6px;
}

.box::-webkit-scrollbar-track {
  display: none;
}

.box::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: none;
}

.box::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox  
   */
}

@media print {
  /* Hide all elements that are not needed for printing */
  body * {
    visibility: hidden;
  }

  /* Only show the specific content to be printed */
  #print-section {
    visibility: visible;
  }
}

@media (max-width: 580px) {
  .feed-table table {
    width: 500px !important;
  }
}

.vital-modal::-webkit-scrollbar {
  display: none;
}

.img-carousel .ant-drawer-close {
  color: white !important;
}

.slick-arrow {
  background-color: white !important;
  color: black !important;
  border-radius: 50% !important;
  padding: 20px !important;
}

.slick-prev::after {
  top: 15px !important;
  left: 16px !important;
}

.slick-next::after {
  top: 15px !important;
  left: 12px !important;
}

/* .gallery {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-width: 33%;
  -moz-column-width: 33%;
  column-width: 33%;
  padding: 10px;
  column-gap: 10px;
  row-gap: 10px;
}

.gallery .pics {
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  cursor: pointer;
}

.gallery .pics img {
  margin: auto;
}


@media (max-width: 680px) {
    .gallery {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
    }
} */

/* .image-grid {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  align-items: center;
  width: 50%;
  margin: auto;
}

.image-grid-item {
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.image-grid-item img {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
} */

.masonry-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -10px; /* Adjust according to gap */
  width: 100%;
  /* align-items: center; */
  justify-content: center;
}

.masonry-grid-column {
  padding-left: 10px; /* Adjust according to gap */
  background-clip: padding-box;
}

.image-grid-item {
  margin-bottom: 10px; /* Adjust according to gap */
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional */
}

.image-grid-item img {
  width: 100%;
  height: auto;
  object-fit: cover;
  display: block;
}
/* 
.image-grid-item img:hover {
  transform: scale(1.02);
  transition: transform 0.3s ease;
} */

/* .feed-page {
  scroll-margin-top: 80px;
} */

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.ant-modal-content {
  border-radius: 15px !important;
  background-color: #ffffff !important;
}

.vitals-border {
  border: 1px solid #06AEB6 !important;
}
.vital-text {
  color: #06AEB6 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #06AEB6 !important;
  border-color: #06AEB6 !important;
}