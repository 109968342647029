/* DoctorsTable.css */

.table-styled {
    margin-top: 20px;
    border-radius: 5px;
    /* overflow: hidden; */
  }
  
  thead.thead-dark th {
    background-color: #343a40;
    color: #fff;
  }
  